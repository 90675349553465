

















import { Component, Prop, Vue } from "vue-property-decorator";
import ProduceNavVue from "@/components/product/ProductNav.vue";
import SubNavVue from "@/components/common/subNav/SubNav.vue";
import { HttpHelper } from "@/helper/httpHelper";
import { AxiosResponse } from "axios";

@Component({
  components: {
    "product-nav": ProduceNavVue,
    "sub-nav": SubNavVue,
  },
})
export default class CourseVue extends Vue {
  /** 产品名 */
  @Prop({
    type: String,
    default: "",
  })
  prodName?: string;

  @Prop({
    type: String,
    default: "",
  })
  prodCode?: string;

  /** 导航信息 */
  @Prop({
    type: Array,
    default: [],
  })
  linkData?: Array<any>;

  @Prop({
    type: Array,
    default: [],
  })
  tabList?: Array<any>;

  mounted() {}
}
