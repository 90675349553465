









import { Component, Prop, Vue } from "vue-property-decorator";
import QaVue from "../qa/Qa.vue";
import { RouterHelper } from "@/router/routerHelper";
import { HttpHelper } from "@/helper/httpHelper";
import { AxiosResponse } from "axios";

@Component({
  components: {
    qa: QaVue,
  },
})
export default class GhostIIQaVue extends Vue {
  prodName: string = "Ghost II";
  prodCode: string = "ghostII";

  tabList: Array<any> = RouterHelper.getProdRouterConfig(this.prodCode);
 

  linkData: Array<any> = [
    { name: "Home", link: "/" },
    { name: "Suppot", link: "#" },
    { name: this.prodName, link: ("/product/" + this.prodCode) },
    { name: "FAQ", link: "#" },
  ];
}
