










import { Component, Prop, Vue } from "vue-property-decorator";    
import { RouterHelper } from '@/router/routerHelper';
import CourseVue from '../course/Course.vue';

@Component({
  components: {  
      'course':CourseVue
  },
})
export default class DroneBitDownloadVue extends Vue { 
    prodName: string = "Drone:bit";
    prodCode: string = "droneBit";
    tabList: Array<any> = RouterHelper.getProdRouterConfig(this.prodCode)  ;
    

    linkData:Array<any> = [
        { name:'Home',link:'/'},
        { name: 'Suppot',link:'#'},
        { name: this.prodName, link: ("/product/" + this.prodCode) },
        { name:'COURSE',link:'#'}, 
    ];
 
}
