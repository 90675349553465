import { Component, Prop, Vue } from "vue-property-decorator";
import GlobalHelper from '../../../helper/resizeHelper';
import ResizeHelper from '../../../helper/resizeHelper';
import { HttpHelper } from '../../../helper/httpHelper';
import axios from 'axios';
import { AxiosResponse } from 'axios';
import { ConfigMgr } from '../../../config';

@Component({})
export default class NavHeaderVue extends Vue {

    /** 产品中心的x坐标 */
    prodCenterLeft: number = 0;

    langUrl:string = ConfigMgr.URL_ZH_CN ;

    tabList: Array<any> = [
        { label: "Products", isProdCenter: true, link: '#' },
        { label: "Where To Buy", link: '/beADistributor' },
        { label: "Events", link: '/events' },
        { label: "Support", link: '#', isSupport: true },
        { label: "About Us", link: '/aboutUs' },
    ];

    prodData: Array<any> = [];

    mounted() {

        HttpHelper.getJsonData('prod').then((axios: AxiosResponse) => {
            var data: any = axios.data;

            this.prodData =  [
                {
                    icon: data.liteBeeWing ? data.liteBeeWing.icon : '',
                    name: 'LiteBee Wing',
                    link: '/product/liteBeeWing'
                },
                {
                    icon: data.ghostII ? data.ghostII.icon : '',
                    name: 'Ghost II',
                    link: '/product/ghostII'
                },
                {
                    icon: data.liteBeeWingFm ? data.liteBeeWingFm.icon : '',
                    name: 'LiteBee Wing FM',
                    link: '/product/liteBeeWingFm'
                },
                {
                    icon: data.liteBeeBrix3.icon ? data.liteBeeBrix3.icon : '',
                    name: 'LiteBee Brix III',
                    link: '/product/liteBeeBrix3'
                },
                {
                    icon: data.armor90.icon ? data.armor90.icon : '',
                    name: 'Armor 90',
                    link: '/product/armor90'
                },
                {
                    icon: data.liteBeeStars.icon ? data.liteBeeStars.icon : '',
                    name: 'LiteBee Stars',
                    link: '/product/liteBeeStars'
                },
                {
                    icon: data.liteBeeSky.icon ? data.liteBeeSky.icon : '',
                    name: 'LiteBee Sky',
                    link: '/product/liteBeeSky'
                },
                {
                    icon: data.droneBit.icon ? data.droneBit.icon : '',
                    name: 'Drone:bit',
                    link: '/product/droneBit'
                },
            ];

        });
 
        this.$nextTick(() => {
            ResizeHelper.instance.addOnResizeHandle(this.updateProdLeft);
            this.updateProdLeft();
        });
    }

    updateProdLeft() {
        var prodCenterLi: any = this.$refs['prodCenterLi'];
        if (prodCenterLi && prodCenterLi[0]) {
            this.prodCenterLeft = -prodCenterLi[0].offsetLeft;
        }
    }


    /** 保存跳往的语言站点 */
    saveJumpLang() {
        ConfigMgr.saveLastJumpLang();
    }
}