






























































import { Component, Prop, Vue } from "vue-property-decorator";
import ProduceNav from "@/components/product/ProductNav.vue";
import ProduceNavVue from "@/components/product/ProductNav.vue";
import { RouterHelper } from "@/router/routerHelper";

@Component({
  components: {
    "product-nav": ProduceNavVue,
  },
})
export default class DroneBitVue extends Vue {
  tabList: Array<any> = RouterHelper.getProdRouterConfig("droneBit");

  mounted() {
    new (<any>window).WOW().init();
  }
}
