











import { Component, Prop, Vue } from "vue-property-decorator";  
import QaVue from '../qa/Qa.vue';
import VideoVue from '../video/Video.vue';
import DownloadVue from '../download/Download.vue';
import { RouterHelper } from '@/router/routerHelper';

@Component({
  components: {  
      'download':DownloadVue
  },
})
export default class LiteBeeWingFmDownloadVue extends Vue { 

    
    prodName:string = 'LiteBee Wing FM' ;
    prodCode:string = 'liteBeeWingFm' ;
    tabList: Array<any> = RouterHelper.getProdRouterConfig(this.prodCode)  ;

    linkData:Array<any> = [
        { name:'Home',link:'/'},
        { name: 'Suppot',link:'#'},
        { name: this.prodName, link:'/product/ghostII'},
        { name:'DOWNLOAD',link:'#'}, 
    ];
 
}
