




































































































































































































import { Component, Prop, Provide, Vue } from "vue-property-decorator";
import Axios, { AxiosResponse } from "axios";
import { ConfigMgr } from "@/config";
import { HttpHelper } from "../../helper/httpHelper";
import MetaHelper from "../../helper/metaHelper";

@Component({})
export default class MainVue extends Vue {
  bannerList: Array<any> = [];
  centerVideo: any = {};
  bannerSwiperIndex: number = 0;

  /** 产品索引 */
  prodIndex: number = 0;

  prodList: Array<any> = [
    {
      name: "LiteBee Wing",
      desc: "Fly the drone as your code",
      img: "https://www.litebee.com/assets/main/3.png",
      link: "/product/liteBeeWing",
    },
    {
      name: "LiteBee Wing FM",
      desc: "Fly the drone as your code",
      img: "https://www.litebee.com/assets/main/2.png",
      link: "/product/liteBeeWingFm",
    },
    {
      name: "Ghost II",
      desc: "Fly the drone as your code",
      img: "https://www.litebee.com/assets/main/1.png",
      link: "/product/ghostII",
    },
  ];

  onClickBannerSwiperProgress(index: number) {
    this.swiperBanner.slideTo(index);
    this.bannerSwiperIndex = index;
  }

  onChangeProdIndex(index: number) {
    this.prodIndex = index;
  }

  get swiperBanner() {
    return (this.$refs["bannerSwiper"] as any).swiper;
  }

  swiperBannerOption: any = {
    autoplay: 8000,
    initialSlide: 0,
    disableOnInteraction: true, // 手动切换之后继续自动轮播
    loop: false,
    onSlideChangeStart: (swiper: any) => {
      this.$nextTick(() => {
        this.changeBannerSwiperIndex(swiper.realIndex);
      });
    },
  };

  changeBannerSwiperIndex(index: number) {
    this.bannerSwiperIndex = index;
  }

  mounted() {
    HttpHelper.getJsonData("main").then((result: AxiosResponse) => {
      this.bannerList = result.data.banner || [];
      this.centerVideo = result.data.video || {};
    });
  }
}
