











import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class SubNavVue extends Vue {

    @Prop({
        type:Array ,
        default:[],
    })linkData?:Array<any> ;

    @Prop({
        type:String ,
        default: '#ffffff',
    })bgColor?:string ;
  
}
