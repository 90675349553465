

export default class ResizeHelper {

    private static _instance:ResizeHelper ;

    public static get instance():ResizeHelper {
        if(!this._instance) {
            this._instance = new ResizeHelper();
        }
        return this._instance ;
    }

    resizeHandlers:Array<any> = [];
    timerRefresh:number = 0 ;
     
    constructor() {
        window.onresize = ()=>{
            if(this.timerRefresh) {
                clearTimeout(this.timerRefresh);
                this.timerRefresh = 0 ;
            }
            this.timerRefresh = setTimeout(()=>{
                this.onResize();
            },200); 
        } ; 
    }

    onResize() { 
        this.resizeHandlers.forEach((handler:any)=>{
            handler();
        });
    }

    
    addOnResizeHandle(handler:any) {
        this.resizeHandlers.push(handler) ;
    }

}