




























































import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class ProduceNavVue extends Vue {
  @Prop({
    type: Number,
    default: 0,
  })
  tabIndex?: number;

  @Prop({
    type: String,
    default : '#F3F3F3'
  })
  bgColor?:string ;

  @Prop({
    type: String,
    default: "",
  })
  title?: string;

  @Prop({
    type: Array,
    default: [],
  })
  tabList?: Array<any>;
}
