







































































































































































































































































































































































































































































































import { HttpHelper } from "@/helper/httpHelper";
import { AxiosResponse } from "axios";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class BeADistributorVue extends Vue {
  name: string = "";
  nameError: boolean = false;

  email: string = "";
  emailError: boolean = false;

  phone: string = "";
  phoneError: boolean = false;

  demand: string = "";
  demandError: boolean = false;

  country: string = "";
  countryError: boolean = false;

  company: string = "";

  // checked: boolean = false;

  showAsSuccess: boolean = false;

  email1:string = 'sales@litebee.com' ;

  get canAs(): boolean {
    // return this.name &&
    //   this.email &&
    //   this.phone &&
    //   this.demand &&
    //   this.country
    //   ? true
    //   : false;
    return true;
  }

  asDistributor() {
    var errorFlag = false;
    if (this.name == "") {
      this.nameError = true;
      errorFlag = true;
    }
    if (this.email == "") {
      this.emailError = true;
      errorFlag = true;
    }
    if (this.phone == "") {
      this.phoneError = true;
      errorFlag = true;
    }
    if (this.demand == "") {
      this.demandError = true;
      errorFlag = true;
    }
    if (this.country == "") {
      this.countryError = true;
      errorFlag = true;
    }
    if (errorFlag) {
      return;
    }
    if (this.company === "") {
      this.company = "N/A";
    }
    HttpHelper.asPartner(
      this.name,
      this.phone,
      this.email,
      this.demand,
      this.country,
      this.company
    ).then((result: AxiosResponse) => {
      if (result.data.code == 0) {
        this.showAsSuccess = true;
      } else {
        alert(result.data.msg);
      }
    });
    this.gtag_report_conversion();
  }

  nameValidateInput() {
    this.nameError = !this.name.trim();
  }
  emailValidateInput() {
    this.emailError = !this.email.trim();
  }
  phoneValidateInput() {
    this.phoneError = !this.phone.trim();
  }
  countryValidateInput() {
    this.countryError = !this.country.trim();
  }
  demandValidateInput() {
    this.demandError = !this.demand.trim();
  }

  submitData() {
    return false;
  }

  mounted() {}

  gtag_report_conversion(url?: string) {
    var callback = () => {
      if (url) {
        window.location.href = url;
      }
      console.log("gtag======>event:conversion=>callback");
    };
    var gtag: any = (window as any).gtag;
    console.log("gtag======>", gtag);
    if (gtag) {
      gtag("event", "conversion", {
        send_to: "AW-586497437/mAeDCL75_O4BEJ371JcC",
        event_callback: callback,
      });
      console.log("gtag======>event:conversion");
    }
    return false;
  }
}
