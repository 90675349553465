import Vue from 'vue';
import store from './store';
import AppVue from './app/App.vue';
import './assets/css/reset.css';
import './assets/css/common.css';
import 'swiper/css/swiper.css';
// import VueMeta from 'vue-meta' ;
// Vue.use(VueMeta, { 
//   refreshOnceOnNavigation: true
// })

// import MetaInfo from 'vue-meta-info'

// Vue.use(MetaInfo)
// import 'babel-polyfill'
// import Es6Promise from 'es6-promise'
// require('es6-promise').polyfill()
// Es6Promise.polyfill()

// import axios, { AxiosResponse, AxiosRequestConfig } from 'axios'
import router from './router/index';
import RouterConfig from './router/config';
import { ConfigMgr } from './config';
Vue.use(require('vue-awesome-swiper'));
Vue.config.productionTip = false;
// router.beforeEach((to, from, next) => {
//   // chrome
//   document.body.scrollTop = 0 ;
//   // firefox
//   document.documentElement.scrollTop = 0 ;
//   // safari
//   (window as any).pageYOffset = 0 ; 
// });

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面meta */
  var meta = to.meta || {};
  meta.content = meta.content || {};

  var title: string = meta.title || RouterConfig.defaultTitle;
  var keywords: string = meta.content.keywords || RouterConfig.defaultKeywords;
  var description: string = meta.content.description || RouterConfig.defaultDesc;

  document.title = title;
  var metaKeywords: any = document.querySelector('meta[name="keywords"]');
  var metaDescription: any = document.querySelector('meta[name="description"]');
  if (metaKeywords) {
    metaKeywords.setAttribute('content', keywords);
  }
  if (metaDescription) {
    metaDescription.setAttribute('content', description);
  }
  next();
});


router.afterEach((to: any) => {
  if (to.path.indexOf('/edu/course') >= 0
    || to.path.indexOf('/newsMore') >= 0
    || to.path.indexOf('/beADistributor') >= 0) {

  }
  else {
    window.scrollTo(0, 0);
  }

})

new Vue({
  router,
  store,
  render: (h) => h(AppVue),
  mounted() {
    document.dispatchEvent(new Event('render-event'));  
  }
}).$mount('#app');


