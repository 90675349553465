

















































import { Component, Prop, Vue } from "vue-property-decorator";
import ProduceNavVue from "@/components/product/ProductNav.vue";
import SubNavVue from "@/components/common/subNav/SubNav.vue";
import { HttpHelper } from "@/helper/httpHelper";
import { AxiosResponse } from "axios";

@Component({
  components: {
    "product-nav": ProduceNavVue,
    "sub-nav": SubNavVue,
  },
})
export default class VideoVue extends Vue {
  /** 产品名 */
  @Prop({
    type: String,
    default: "",
  })
  prodName?: string;

  @Prop({
    type: String,
    default: "",
  })
  prodCode?: string;

  /** 导航信息 */
  @Prop({
    type: Array,
    default: [],
  })
  linkData?: Array<any>;

  @Prop({
    type: Array,
    default: [],
  })
  tabList?: Array<any>;

  tabIndex: number = 0;

  /** 是否显示加载更多 */
  isShowGetMore: boolean = true;

  videoData: Array<any> = [];

  getMore() {
    this.isShowGetMore = false;
  }

  onSelectTab(index: number) {
    this.tabIndex = index;
    this.isShowGetMore = true;
  }

  mounted() {
    this.$nextTick(() => {
      HttpHelper.getProdVideoJson(this.prodCode || "").then(
        (response: AxiosResponse) => {
          // var all = {
          //   name: "全部",
          //   list: new Array<any>(),
          // };
          // var dataList: Array<any> = response.data.data || [];
          // dataList.forEach((item: any) => {
          //   item.list.forEach((video: any) => {
          //     all.list.push(video);
          //   });
          // });
          // dataList.unshift(all);
          this.videoData = response.data.data;
        }
      );
    });
  }
}
