


































































































































































































































































































import { HttpHelper } from "@/helper/httpHelper";
import { AxiosResponse } from "axios";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class AboutUsVue extends Vue {
  email: string = "sales@litebee.com";

  showMore: boolean = false;

  newsList: Array<any> = [];

  mounted() {
    new (<any>window).WOW().init();

    HttpHelper.getJsonData("aboutUs").then((result: AxiosResponse) => {
      this.newsList = result.data.newsList || [];
    });
  }

  showMoreNews() {
    this.showMore = true;
  }
}
