import { CookieHelper } from './helper/cookieHelper';
import { HttpHelper } from './helper/httpHelper';




export class ConfigMgr {

    /** 当前版本标识 */
    static versonTag: string = '2020-11-02|02';

    /** 中文站定义 */
    static LANG_ZH_CN: string = 'zh-cn';

    /** 英文站定义 */
    static LANG_EN: string = 'en';

    /** 检查是不是开发环境 */
    static checkIsDev() {
        return process.env.NODE_ENV == 'development';
    }

    /** json目录的地址 */
    static get jsonDir() {
        if (this.checkIsDev()) {
            return 'http://localhost:8091/json/';
        }
        return 'https://www.litebee.com/json/';
    }


    /** 获得api的访问地址 */
    static get apiHost() {
        return 'https://api.litebee.com';
    }


    /** 中文站地址 */
    static readonly URL_ZH_CN: string = 'https://cn.litebee.com/';

    /** 英文站地址 */
    static readonly URL_EN: string = 'https://www.litebee.com/';
 

    /** 保存最近跳转的语种站点 */
    static saveLastJumpLang() {
        CookieHelper.setCookie('lang', ConfigMgr.LANG_ZH_CN);
    }



    /** 语言处理 */
    static dealLang() {
        var curLang: string = CookieHelper.getCookie('lang');
        console.log('cookie:lang=======>', curLang);
        if (!curLang) {
            curLang = window.navigator.language.toLowerCase();
        }
        console.log('lang=======>', curLang);
        // 如果当前设定语种包含中文站，则自动跳去中文站
        if (curLang.indexOf(ConfigMgr.LANG_ZH_CN) >= 0) {
            window.location.href = ConfigMgr.URL_ZH_CN;
            return true;
        }
    }



}
