

export class CommonHelper {

    /** 获得链接参数 */
    static getQueryString = function (name: string): string {
        var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
        var r = window.location.search.substr(1).match(reg);
        if (r != null) {
            return unescape(r[2]);
        }
        return '';
    };


    /** 下载文件 */
    static downloadFile(url:string) { 

        // var infos = url.split('.'); 
        // var a = document.createElement("a") ;
        // a.setAttribute("href",url)
        // a.setAttribute("download", new Date().getTime() + '.' + infos[infos.length - 1])
        // a.setAttribute("target","_blank")
        // let clickEvent = document.createEvent("MouseEvents");
        // clickEvent.initEvent("click", true, true);  
        // a.dispatchEvent(clickEvent);

        // return ;

        if (/(iP)/g.test(navigator.userAgent)) {
            alert('您的浏览器不支持下载该文件，请换别的浏览器');
            return false;
        }
        var  isChrome = navigator.userAgent.toLowerCase().indexOf('chrome') > -1;
        var isSafari = navigator.userAgent.toLowerCase().indexOf('safari') > -1; 

        //If in Chrome or Safari - download via virtual link click
        if (isChrome || isSafari ) {
            //Creating new link node.
            var link = document.createElement('a');
            link.href = url;

            if (link.download !== undefined) {
                //Set HTML5 download attribute. This will prevent file from opening if supported.
                var fileName = url.substring(url.lastIndexOf('/') + 1, url.length);
                link.download = fileName;
            }

            //Dispatching click event.
            if (document.createEvent) {
                var e = document.createEvent('MouseEvents');
                e.initEvent('click', true, true);
                link.dispatchEvent(e);
                return true;
            }
        }

        // Force file download (whether supported by server).
        if (url.indexOf('?') === -1) {
            url += '?download';
        }

        window.open(url, '_self');
    }



     
}