import { render, staticRenderFns } from "./Main.vue?vue&type=template&id=4c3b9b29&scoped=true&comments=true&"
import script from "./Main.vue?vue&type=script&lang=ts&"
export * from "./Main.vue?vue&type=script&lang=ts&"
import style0 from "./main.css?vue&type=style&index=0&id=4c3b9b29&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c3b9b29",
  null
  
)

export default component.exports