import { Component, Prop, Vue } from 'vue-property-decorator'; 
import NavHeaderVue from '../components/common/navHeader/NavHeader.vue';
import FootVue from '../components/foot/Foot.vue';

 

@Component({
    components:{
        'nav-header': NavHeaderVue,
        'foot':FootVue
    }
})
export default class AppVue extends Vue { 
 
} 