import { Component, Prop, Vue } from "vue-property-decorator";
import { HttpHelper } from '../../helper/httpHelper';
import { AxiosResponse } from 'axios';

@Component({})
export default class FootVue extends Vue {
   
    email:string = '' ;
  
                                
    modules:Array<any> = [

        {  
            label:'COMPANY',
            list:[
                {
                    name:'About Us', 
                    link:'/aboutUs',
                    out:false
                },
                {
                    name:'Become Our Distributor', 
                    link:'/beADistributor',
                    out:false ,
                },
                {
                    name:'Privacy Policy', 
                    link:'/privacyPolicy',
                    out:false ,
                },
                {
                    name:'Terms of Use', 
                    link:'/termsOfUse',
                    out:false ,
                }
            ]
        }, 
        {  
            label:'PRODUCTS',
            list:[
                {
                    name:'LiteBee Wing', 
                    link:'/product/liteBeeWing',
                    out:false
                },
                {
                    name:'LiteBee Wing FM', 
                    link:'/product/liteBeeWingFm',
                    out:false
                },
                {
                    name:'Ghost II', 
                    link:'/product/ghostII',
                    out:false ,
                },
                {
                    name:'LiteBee Brix III', 
                    link:'/product/liteBeeBrix3',
                    out:false ,
                },
                {
                    name:'Armor 90', 
                    link:'/product/armor90',
                    out:false ,
                },
                {
                    name:'LiteBee Stars', 
                    link:'/product/dronelightshows',
                    out:false ,
                },
                {
                    name:'Drone:bit', 
                    link:'/product/droneBit',
                    out:false ,
                }
            ]
        }, 
    ];
    

    /** 是否展示订单成功窗口 */
    showSubSuccess: boolean = false ;
 
    /** 订阅 */
    subscribe(){ 
        if(!this.email) {
            alert('please enter your email');
            return ;
        } 
        HttpHelper.subByEmail(this.email).then((result: AxiosResponse)=>{
            if(result.data.code == 0 ) {
                this.showSubSuccess = true ;
            } 
            else {
                alert(result.data.msg) ;
            }
        });
    }
    
    mounted() { 
    }
}