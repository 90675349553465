
import MainVue from '../views/main/Main.vue';
import AboutUsVue from '../views/aboutUs/AboutUs.vue';
 
import NewsDetailVue from '../views/news/detail/newsDetail.vue'; 
import LiteBeeWingFmVue from '../views/product/liteBeeWingFm/Index.vue';
import LiteBeeWingFmQaVue from '../views/product/liteBeeWingFm/qa.vue';
import LiteBeeWingFmVideoVue from '../views/product/liteBeeWingFm/video.vue';
import LiteBeeWingFmDownloadVue from '../views/product/liteBeeWingFm/download.vue';
import GhostIIVue from '../views/product/ghostII/Index.vue';
import LiteBeeWingVue from '../views/product/liteBeeWing/Index.vue';  
import LiteBeeWingQaVue from '../views/product/liteBeeWing/qa.vue';
import LiteBeeWingDownloadVue from '../views/product/liteBeeWing/download.vue';
import LiteBeeWingVideoVue from '../views/product/liteBeeWing/video.vue';
import GhostIIQaVue from '../views/product/ghostII/qa.vue';
import GhostIIDownloadVue from '../views/product/ghostII/download.vue';
import GhostIIVideoVue from '../views/product/ghostII/video.vue'; 
import TermsOfUseVue from '../views/aboutUs/TermsOfUse.vue';
import PrivacyPolicyVue from '../views/aboutUs/PrivacyPolicy.vue';
import EventsVue from '../views/events/Events.vue';
import BeADistributorVue from '../views/aboutUs/BeADistributor.vue';
import GhostIICourseVue from '../views/product/ghostII/course.vue';
import LiteBeeWingFmCourseVue from '../views/product/liteBeeWingFm/course.vue';
import LiteBeeWingCourseVue from '../views/product/liteBeeWing/course.vue';
import LiteBeeBrix3Vue from '../views/product/liteBeeBrix3/Index.vue';
import LiteBeeBrix3QaVue from '../views/product/liteBeeBrix3/qa.vue';
import LiteBeeBrix3VideoVue from '../views/product/liteBeeBrix3/video.vue';
import LiteBeeBrix3DownloadVue from '../views/product/liteBeeBrix3/download.vue';
import LiteBeeBrix3CourseVue from '../views/product/liteBeeBrix3/course.vue';
import Armor90Vue from '../views/product/armor90/Index.vue';
import Armor90QaVue from '../views/product/armor90/qa.vue';
import Armor90VideoVue from '../views/product/armor90/video.vue';
import Armor90DownloadVue from '../views/product/armor90/download.vue';
import Armor90CourseVue from '../views/product/armor90/course.vue';
import LiteBeeStarsVue from '../views/product/liteBeeStars/Index.vue';
import LiteBeeStarsQaVue from '../views/product/liteBeeStars/qa.vue';
import LiteBeeStarsVideoVue from '../views/product/liteBeeStars/video.vue';
import LiteBeeStarsDownloadVue from '../views/product/liteBeeStars/download.vue';
import LiteBeeStarsCourseVue from '../views/product/liteBeeStars/course.vue';
import LiteBeeSkyVue from '../views/product/liteBeeSky/Index.vue';
import LiteBeeSkyQaVue from '../views/product/liteBeeSky/qa.vue';
import LiteBeeSkyVideoVue from '../views/product/liteBeeSky/video.vue';
import LiteBeeSkyDownloadVue from '../views/product/liteBeeSky/download.vue';
import LiteBeeSkyCourseVue from '../views/product/liteBeeSky/course.vue';
import DroneBitVue from '../views/product/droneBit/Index.vue';
import DroneBitQaVue from '../views/product/droneBit/qa.vue';
import DroneBitVideoVue from '../views/product/droneBit/video.vue';
import DroneBitDownloadVue from '../views/product/droneBit/download.vue';
import DroneBitCourseVue from '../views/product/droneBit/course.vue';

/** 路由配置 */
export default class RouterConfig {

 
  static defaultTitle:string = 'LiteBee Kids Drones | Professional Programming Drone for Kids| Coding for Kids';
  static defaultDesc:string = 'Leading in steam educational and programming drones. We are dedicated to design and create the best drone for kids with camera. With the features of easy-to-fly and programmable, LiteBee drone can be a funny gift and applied to the course of coding for kids.';
  static defaultKeywords:string = 'aerial photography, kids drone, aerial view, drones for kids, coding for kids,';


  static getAllPage(): Array<string> {
    var list: Array<string> = [];
    this.alls.forEach((item: any) => {
      list.push(item.path);
    });
    return list;
  }

  static alls: Array<any> = [ 
    {
      path: '/aboutUs',
      name: 'aboutUs',
      component: AboutUsVue,
      meta: {
        title: 'LiteBee Educational Drones for Kids | About Us',
        content: {
          keywords: 'steam education, drone for kids, programming, extension, curiosity and creativity',
          description: "Our core is steam education. LiteBee drones are designed especially for kids' education. With the features of programmable and extendable, the drone stimulates kids' curiosity and creativity, and increases critical thinking, decision-making and problem-solving skills.",
        },
      } 
    },
    {
      path: '/termsOfUse',
      name: 'termsOfUse',
      component: TermsOfUseVue,
      meta: {
        title: 'LiteBee Educational Drones for Kids | Terms of Use',
        content: {
          keywords: 'litebee official, website statement, terms of use, drone registration',
          description: "Official website privacy policy of www.litebee.com. By browsing this Website, you acknowledge that you have read, understood and accepted the following terms",
        },
      } 
    },
    {
      path: '/privacyPolicy',
      name: 'privacyPolicy',
      component: PrivacyPolicyVue,
      meta: {
        title: 'LiteBee Educational Drones for Kids | Privacy Policy',
        content: {
          keywords: 'litebee official, website statement, privacy policy, drone registration',
          description: "Official website privacy policy of www.litebee.com. This Policy applies to LiteBee websites, products, and services that display or provide links to this Policy",
        },
      } 
    },

    {
      path: '/beADistributor',
      name: 'beADistributor',
      component: BeADistributorVue,
      meta: {
        title: 'Best Mini Drones for Sale | Become a Distributor',
        content: {
          keywords: 'meet demand, become a distributor, discuss demand, customer service',
          description: "Want to discuss your demand with us? or just want to become our distributor? Fill in the following form, and we will contact you soon!",
        },
      } 
    },
    


    {
      path: '/product/liteBeeWingFm',
      name: 'liteBeeWingFm',
      component: LiteBeeWingFmVue,
      meta: {
        title: 'Allows Kids to Creat a Mini Drone Swarm by Themselves - LiteBee Wing FM | LiteBee Drone',
        content: {
          keywords: 'drone swarm, drone show, drone movie, programable drone, best drone for kids',
          description: "LiteBee Wing FM is a set of drones that designed especially for drone swarm. It offers an effortless solution to making a mini drone show. Open-source, fully equipped, and easy-programmed, it's one of the best drones for kids to play and learn.",
        },
      } 
    },
    {
      path: '/product/liteBeeWingFm/qa',
      name: 'liteBeeWingFmQa',
      component: LiteBeeWingFmQaVue,
      meta: {
        title: 'Allows Kids to Creat a Mini Drone Swarm by Themselves - LiteBee Wing FM | LiteBee Drone',
        content: {
          keywords: 'drone swarm, drone show, drone movie, programable drone, best drone for kids',
          description: "LiteBee Wing FM is a set of drones that designed especially for drone swarm. It offers an effortless solution to making a mini drone show. Open-source, fully equipped, and easy-programmed, it's one of the best drones for kids to play and learn.",
        },
      } 
    },
    {
      path: '/product/liteBeeWingFm/video',
      name: 'liteBeeWingFmVideo',
      component: LiteBeeWingFmVideoVue,
      meta: {
        title: 'Allows Kids to Creat a Mini Drone Swarm by Themselves - LiteBee Wing FM | LiteBee Drone',
        content: {
          keywords: 'drone swarm, drone show, drone movie, programable drone, best drone for kids',
          description: "LiteBee Wing FM is a set of drones that designed especially for drone swarm. It offers an effortless solution to making a mini drone show. Open-source, fully equipped, and easy-programmed, it's one of the best drones for kids to play and learn.",
        },
      } 
    },
    {
      path: '/product/liteBeeWingFm/download',
      name: 'liteBeeWingFmDownload',
      component: LiteBeeWingFmDownloadVue,
      meta: {
        title: 'To Create a Mini Aerial Swarm | Support-litebee FM',
        content: {
          keywords: 'drone swarm, drone show video, drone with camera, mini drone, drone movie, kids drone',
          description: "Relevant support for LiteBee Wing FM, drones show video, code of drone swarm, drone mobile app, drones course of coding for kids, and drone FAQ",
        },
      } 
    },
    {
      path: '/product/liteBeeWingFm/course',
      name: 'liteBeeWingFmCourse',
      component: LiteBeeWingFmCourseVue,
      meta: {
        title: 'Allows Kids to Creat a Mini Drone Swarm by Themselves - LiteBee Wing FM | LiteBee Drone',
        content: {
          keywords: 'drone swarm, drone show, drone movie, programable drone, best drone for kids',
          description: "LiteBee Wing FM is a set of drones that designed especially for drone swarm. It offers an effortless solution to making a mini drone show. Open-source, fully equipped, and easy-programmed, it's one of the best drones for kids to play and learn.",
        },
      } 
    },
    {
      path: '/product/ghostII',
      name: 'ghostII',
      component: GhostIIVue,
      meta: {
        title: 'Provide Programmable Drones for Kids Around the World - Ghost II | LiteBee Drone',
        content: {
          keywords: 'uav drone, robotics kits, drone with camera, coding games',
          description: "Ghost II, an easy-assembling modular and programmable drone for kids. It's programmable and extendable, and could be a powerful flight platform for multiple robotics kits. With graphic programming software, Ghost II provides a creative way of playing and learning.",
        },
      } 
    },
    {
      path: '/product/ghostII/qa',
      name: 'ghostIIQa',
      component: GhostIIQaVue,
      meta: {
        title: 'Provide Programmable Drones for Kids Around the World - Ghost II | LiteBee Drone',
        content: {
          keywords: 'uav drone, robotics kits, drone with camera, coding games',
          description: "Ghost II, an easy-assembling modular and programmable drone for kids. It's programmable and extendable, and could be a powerful flight platform for multiple robotics kits. With graphic programming software, Ghost II provides a creative way of playing and learning.",
        },
      } 
    },
    {
      path: '/product/ghostII/download',
      name: 'ghostIIDownload',
      component: GhostIIDownloadVue,
      meta: {
        title: 'Help Kids to Learn to Code | Support-ghost II',
        content: {
          keywords: 'drone show video, drone with camera, drone movie, kids drone',
          description: "Relevant support for Ghost II, drones video, drones controller, drone mobile app, drones course of coding for kids, and drone FAQ",
        },
      } 
    },
    {
      path: '/product/ghostII/video',
      name: 'ghostIIVideo',
      component: GhostIIVideoVue,
      meta: {
        title: 'Provide Programmable Drones for Kids Around the World - Ghost II | LiteBee Drone',
        content: {
          keywords: 'uav drone, robotics kits, drone with camera, coding games',
          description: "Ghost II, an easy-assembling modular and programmable drone for kids. It's programmable and extendable, and could be a powerful flight platform for multiple robotics kits. With graphic programming software, Ghost II provides a creative way of playing and learning.",
        },
      } 
    },
    {
      path: '/product/ghostII/course',
      name: 'ghostIICourse',
      component: GhostIICourseVue,
      meta: {
        title: 'Provide Programmable Drones for Kids Around the World - Ghost II | LiteBee Drone',
        content: {
          keywords: 'uav drone, robotics kits, drone with camera, coding games',
          description: "Ghost II, an easy-assembling modular and programmable drone for kids. It's programmable and extendable, and could be a powerful flight platform for multiple robotics kits. With graphic programming software, Ghost II provides a creative way of playing and learning.",
        },
      }  
    },

    {
      path: '/product/liteBeeWing',
      name: 'liteBeeWing',
      component: LiteBeeWingVue,
      meta: {
        title: 'Provide Programmable Kids Drone For coding of STEAM Education - LiteBee Wing | LiteBee Drone',
        content: {
          keywords: 'coding bootcamp, best drones under 100, mini drone, coding for kids, drone for beginner, kids drone',
          description: 'LiteBee Wing is a mini drone for kids, providing kids an aerial toy to have fun and learn. It is easy for kids and beginners to fly, and it can be controlled by coding. Explore the future of aerial kits with LiteBee Wing now!',
        },
      } 
    },
    {
      path: '/product/liteBeeWing/qa',
      name: 'liteBeeWingQa',
      component: LiteBeeWingQaVue,
      meta: {
        title: 'Provide Programmable Kids Drone For coding of STEAM Education - LiteBee Wing | LiteBee Drone',
        content: {
          keywords: 'coding bootcamp, best drones under 100, mini drone, coding for kids, drone for beginner, kids drone',
          description: 'LiteBee Wing is a mini drone for kids, providing kids an aerial toy to have fun and learn. It is easy for kids and beginners to fly, and it can be controlled by coding. Explore the future of aerial kits with LiteBee Wing now!',
        },
      } 
    },
    {
      path: '/product/liteBeeWing/course',
      name: 'liteBeeWingCourse',
      component: LiteBeeWingCourseVue,
      meta: {
        title: 'Provide Programmable Kids Drone For coding of STEAM Education - LiteBee Wing | LiteBee Drone',
        content: {
          keywords: 'coding bootcamp, best drones under 100, mini drone, coding for kids, drone for beginner, kids drone',
          description: 'LiteBee Wing is a mini drone for kids, providing kids an aerial toy to have fun and learn. It is easy for kids and beginners to fly, and it can be controlled by coding. Explore the future of aerial kits with LiteBee Wing now!',
        },
      } 
    },
    {
      path: '/product/liteBeeWing/download',
      name: 'liteBeeWingDownload',
      component: LiteBeeWingDownloadVue,
      meta: {
        title: 'Educational drone Coding for Beginners | Support-litebee wing',
        content: {
          keywords: 'best drones under 100, drone camera, mini drone, drone movie',
          description: 'Relevant support for LiteBee Wing, drones video, drones controller, drone mobile app, drones course of coding for kids, and drone FAQ',
        },
      }  
    },
    {
      path: '/product/liteBeeWing/video',
      name: 'liteBeeWingVideo',
      component: LiteBeeWingVideoVue,
      meta: {
        title: 'Provide Programmable Kids Drone For coding of STEAM Education - LiteBee Wing | LiteBee Drone',
        content: {
          keywords: 'coding bootcamp, best drones under 100, mini drone, coding for kids, drone for beginner, kids drone',
          description: 'LiteBee Wing is a mini drone for kids, providing kids an aerial toy to have fun and learn. It is easy for kids and beginners to fly, and it can be controlled by coding. Explore the future of aerial kits with LiteBee Wing now!',
        },
      }  
    },
    {
      path: '/product/liteBeeBrix3',
      name: 'liteBeeBrix3',
      component: LiteBeeBrix3Vue,
      meta: {
        title: "Building Block drone, Kids' First Drone",
        content: {
          keywords: 'robotic kits, drone, educational toy, stem, building blocks',
          description: 'LiteBee Brix III is a building block drone, with the function of flip, headless, 3 level speeds, throw & go, it provides a chance for kids to learn while having fun.',
        },
      } 
    },
    {
      path: '/product/liteBeeBrix3/qa',
      name: 'liteBeeBrix3Qa',
      component: LiteBeeBrix3QaVue,
      meta: {
        title: "Building Block drone, Kids' First Drone",
        content: {
          keywords: 'robotic kits, drone, educational toy, stem, building blocks',
          description: 'LiteBee Brix III is a building block drone, with the function of flip, headless, 3 level speeds, throw & go, it provides a chance for kids to learn while having fun.',
        },
      }
    },
    {
      path: '/product/liteBeeBrix3/video',
      name: 'liteBeeBrix3Video',
      component: LiteBeeBrix3VideoVue,
      meta: {
        title: "Building Block drone, Kids' First Drone",
        content: {
          keywords: 'robotic kits, drone, educational toy, stem, building blocks',
          description: 'LiteBee Brix III is a building block drone, with the function of flip, headless, 3 level speeds, throw & go, it provides a chance for kids to learn while having fun.',
        },
      }
    },
    {
      path: '/product/liteBeeBrix3/download',
      name: 'liteBeeBrix3Download',
      component: LiteBeeBrix3DownloadVue,
      meta: {
        title: "Building Block drone, Kids' First Drone",
        content: {
          keywords: 'robotic kits, drone, educational toy, stem, building blocks',
          description: 'LiteBee Brix III is a building block drone, with the function of flip, headless, 3 level speeds, throw & go, it provides a chance for kids to learn while having fun.',
        },
      } 
    },
    {
      path: '/product/liteBeeBrix3/course',
      name: 'liteBeeBrix3Course',
      component: LiteBeeBrix3CourseVue,
      meta: {
        title: "Building Block drone, Kids' First Drone",
        content: {
          keywords: 'robotic kits, drone, educational toy, stem, building blocks',
          description: 'LiteBee Brix III is a building block drone, with the function of flip, headless, 3 level speeds, throw & go, it provides a chance for kids to learn while having fun.',
        },
      }
    },
    {
      path: '/product/armor90',
      name: 'armor90',
      component: Armor90Vue,
      meta: {
        title: "Building Block drone, Kids' First Drone",
        content: {
          keywords: 'robotic kits, drone, educational toy, stem, building blocks',
          description: 'LiteBee Brix III is a building block drone, with the function of flip, headless, 3 level speeds, throw & go, it provides a chance for kids to learn while having fun.',
        },
      } 
    },
    {
      path: '/product/armor90/qa',
      name: 'armor90Qa',
      component: Armor90QaVue,
      meta: {
        title: "Building Block drone, Kids' First Drone",
        content: {
          keywords: 'robotic kits, drone, educational toy, stem, building blocks',
          description: 'LiteBee Brix III is a building block drone, with the function of flip, headless, 3 level speeds, throw & go, it provides a chance for kids to learn while having fun.',
        },
      }
    },
    {
      path: '/product/armor90/video',
      name: 'armor90Video',
      component: Armor90VideoVue,
      meta: {
        title: "Building Block drone, Kids' First Drone",
        content: {
          keywords: 'robotic kits, drone, educational toy, stem, building blocks',
          description: 'LiteBee Brix III is a building block drone, with the function of flip, headless, 3 level speeds, throw & go, it provides a chance for kids to learn while having fun.',
        },
      }
    },
    {
      path: '/product/armor90/download',
      name: 'armor90Download',
      component: Armor90DownloadVue,
      meta: {
        title: "Building Block drone, Kids' First Drone",
        content: {
          keywords: 'robotic kits, drone, educational toy, stem, building blocks',
          description: 'LiteBee Brix III is a building block drone, with the function of flip, headless, 3 level speeds, throw & go, it provides a chance for kids to learn while having fun.',
        },
      } 
    },
    {
      path: '/product/armor90/course',
      name: 'armor90Course',
      component: Armor90CourseVue,
      meta: {
        title: "Building Block drone, Kids' First Drone",
        content: {
          keywords: 'robotic kits, drone, educational toy, stem, building blocks',
          description: 'LiteBee Brix III is a building block drone, with the function of flip, headless, 3 level speeds, throw & go, it provides a chance for kids to learn while having fun.',
        },
      }
    },
    {
      path: '/product/liteBeeStars',
      name: 'liteBeeStars',
      component: LiteBeeStarsVue,
      meta: {
        title: "Building Block drone, Kids' First Drone",
        content: {
          keywords: 'robotic kits, drone, educational toy, stem, building blocks',
          description: 'LiteBee Brix III is a building block drone, with the function of flip, headless, 3 level speeds, throw & go, it provides a chance for kids to learn while having fun.',
        },
      } 
    },
    {
      path: '/product/liteBeeStars/qa',
      name: 'liteBeeStarsQa',
      component: LiteBeeStarsQaVue,
      meta: {
        title: "Building Block drone, Kids' First Drone",
        content: {
          keywords: 'robotic kits, drone, educational toy, stem, building blocks',
          description: 'LiteBee Brix III is a building block drone, with the function of flip, headless, 3 level speeds, throw & go, it provides a chance for kids to learn while having fun.',
        },
      }
    },
    {
      path: '/product/liteBeeStars/video',
      name: 'liteBeeStarsVideo',
      component: LiteBeeStarsVideoVue,
      meta: {
        title: "Building Block drone, Kids' First Drone",
        content: {
          keywords: 'robotic kits, drone, educational toy, stem, building blocks',
          description: 'LiteBee Brix III is a building block drone, with the function of flip, headless, 3 level speeds, throw & go, it provides a chance for kids to learn while having fun.',
        },
      }
    },
    {
      path: '/product/liteBeeStars/download',
      name: 'liteBeeStarsDownload',
      component: LiteBeeStarsDownloadVue,
      meta: {
        title: "Building Block drone, Kids' First Drone",
        content: {
          keywords: 'robotic kits, drone, educational toy, stem, building blocks',
          description: 'LiteBee Brix III is a building block drone, with the function of flip, headless, 3 level speeds, throw & go, it provides a chance for kids to learn while having fun.',
        },
      } 
    },
    {
      path: '/product/liteBeeStars/course',
      name: 'liteBeeStarsCourse',
      component: LiteBeeStarsCourseVue,
      meta: {
        title: "Building Block drone, Kids' First Drone",
        content: {
          keywords: 'robotic kits, drone, educational toy, stem, building blocks',
          description: 'LiteBee Brix III is a building block drone, with the function of flip, headless, 3 level speeds, throw & go, it provides a chance for kids to learn while having fun.',
        },
      }
    },
    {
      path: '/product/liteBeeSky',
      name: 'liteBeeSky',
      component: LiteBeeSkyVue,
      meta: {
        title: "Building Block drone, Kids' First Drone",
        content: {
          keywords: 'robotic kits, drone, educational toy, stem, building blocks',
          description: 'LiteBee Brix III is a building block drone, with the function of flip, headless, 3 level speeds, throw & go, it provides a chance for kids to learn while having fun.',
        },
      } 
    },
    {
      path: '/product/liteBeeSky/qa',
      name: 'liteBeeSkyQa',
      component: LiteBeeSkyQaVue,
      meta: {
        title: "Building Block drone, Kids' First Drone",
        content: {
          keywords: 'robotic kits, drone, educational toy, stem, building blocks',
          description: 'LiteBee Brix III is a building block drone, with the function of flip, headless, 3 level speeds, throw & go, it provides a chance for kids to learn while having fun.',
        },
      }
    },
    {
      path: '/product/liteBeeSky/video',
      name: 'liteBeeSkyVideo',
      component: LiteBeeSkyVideoVue,
      meta: {
        title: "Building Block drone, Kids' First Drone",
        content: {
          keywords: 'robotic kits, drone, educational toy, stem, building blocks',
          description: 'LiteBee Brix III is a building block drone, with the function of flip, headless, 3 level speeds, throw & go, it provides a chance for kids to learn while having fun.',
        },
      }
    },
    {
      path: '/product/liteBeeSky/download',
      name: 'liteBeeSkyDownload',
      component: LiteBeeSkyDownloadVue,
      meta: {
        title: "Building Block drone, Kids' First Drone",
        content: {
          keywords: 'robotic kits, drone, educational toy, stem, building blocks',
          description: 'LiteBee Brix III is a building block drone, with the function of flip, headless, 3 level speeds, throw & go, it provides a chance for kids to learn while having fun.',
        },
      } 
    },
    {
      path: '/product/liteBeeSky/course',
      name: 'liteBeeSkyCourse',
      component: LiteBeeSkyCourseVue,
      meta: {
        title: "Building Block drone, Kids' First Drone",
        content: {
          keywords: 'robotic kits, drone, educational toy, stem, building blocks',
          description: 'LiteBee Brix III is a building block drone, with the function of flip, headless, 3 level speeds, throw & go, it provides a chance for kids to learn while having fun.',
        },
      }
    },
    {
      path: '/product/droneBit',
      name: 'droneBit',
      component: DroneBitVue,
      meta: {
        title: "Building Block drone, Kids' First Drone",
        content: {
          keywords: 'robotic kits, drone, educational toy, stem, building blocks',
          description: 'LiteBee Brix III is a building block drone, with the function of flip, headless, 3 level speeds, throw & go, it provides a chance for kids to learn while having fun.',
        },
      } 
    },
    {
      path: '/product/droneBit/qa',
      name: 'droneBitQa',
      component: DroneBitQaVue,
      meta: {
        title: "Building Block drone, Kids' First Drone",
        content: {
          keywords: 'robotic kits, drone, educational toy, stem, building blocks',
          description: 'LiteBee Brix III is a building block drone, with the function of flip, headless, 3 level speeds, throw & go, it provides a chance for kids to learn while having fun.',
        },
      }
    },
    {
      path: '/product/droneBit/video',
      name: 'droneBitVideo',
      component: DroneBitVideoVue,
      meta: {
        title: "Building Block drone, Kids' First Drone",
        content: {
          keywords: 'robotic kits, drone, educational toy, stem, building blocks',
          description: 'LiteBee Brix III is a building block drone, with the function of flip, headless, 3 level speeds, throw & go, it provides a chance for kids to learn while having fun.',
        },
      }
    },
    {
      path: '/product/droneBit/download',
      name: 'droneBitDownload',
      component: DroneBitDownloadVue,
      meta: {
        title: "Building Block drone, Kids' First Drone",
        content: {
          keywords: 'robotic kits, drone, educational toy, stem, building blocks',
          description: 'LiteBee Brix III is a building block drone, with the function of flip, headless, 3 level speeds, throw & go, it provides a chance for kids to learn while having fun.',
        },
      } 
    },
    {
      path: '/product/droneBit/course',
      name: 'droneBitCourse',
      component: DroneBitCourseVue,
      meta: {
        title: "Building Block drone, Kids' First Drone",
        content: {
          keywords: 'robotic kits, drone, educational toy, stem, building blocks',
          description: 'LiteBee Brix III is a building block drone, with the function of flip, headless, 3 level speeds, throw & go, it provides a chance for kids to learn while having fun.',
        },
      }
    },
  
    {
      path: '/news/detail/*',
      name: 'newsDetail',
      component: NewsDetailVue,
      meta: {
        title: '最新企业及产品动态-创客火无人机',
        content: {
          keywords: '新闻中心,创客教育,最新动态',
          description: '创客火无人机最新动态，新产品发布信息，产品评测及用户故事等',
        },
      } 
    }, 
    {
      path: '/events',
      name: 'events',
      component: EventsVue, 
      meta: {
        title: 'Robot Kit for Kids | Coding Drones Racing | Event',
        content: {
          keywords: 'flying competition, kids innovation, robotics kits, programming club, coding competition',
          description: 'Are LiteBee drones reliable and qualified for educational activities? There are events that LiteBee drone has participated in',
        },
      } 
    },
    {
      path: '/',
      name: 'main',
      component: MainVue, 
    }, 
  ]


}