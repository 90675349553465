

export class RouterHelper {


    /** 获取产品路由配置 */
    static getProdRouterConfig( name: string ) {
         
        return [
            {
                name: "DOWNLOAD",
                link: '/product/' + name + '/download' ,
              },
              {
                name: "VIDEO",
                link: '/product/' + name + '/video',
              }, 
              {
                name: "COURSE",
                link: '/product/' + name + '/course',
              },
              {
                name: "FAQ",
                link: '/product/' + name + '/qa',
              },
        ]
    }



}