



































































import FootVue from "./foot";
export default FootVue;
