











































































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import SubNavVue from "@/components/common/subNav/SubNav.vue";

@Component({
  components: {
    "sub-nav": SubNavVue,
  },
})
export default class EventsVue extends Vue {
  subData: Array<any> = [
    {
      name: "Home",
      link: "/",
    }, 
    {
      name: "Events",
      link: "#",
    },
  ];
}
