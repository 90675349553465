







import { Component, Prop, Vue } from "vue-property-decorator";
import QaVue from "../qa/Qa.vue";
import VideoVue from "../video/Video.vue";
import { RouterHelper } from "@/router/routerHelper";

@Component({
  components: {
    video_: VideoVue,
  },
})
export default class DroneBitVideoVue extends Vue {
  prodName: string = "Drone:bit";
  prodCode: string = "droneBit";
  tabList: Array<any> = RouterHelper.getProdRouterConfig(this.prodCode);

 
  linkData: Array<any> = [
    { name: "Home", link: "/" },
    { name: "Suppot", link: "#" },
    { name: this.prodName, link: "/product/" + this.prodCode },
    { name: "VIDEO", link: "#" },
  ];
}
