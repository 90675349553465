









import { Component, Prop, Vue } from "vue-property-decorator";
import QaVue from "../qa/Qa.vue";
import VideoVue from "../video/Video.vue";
import DownloadVue from "../download/Download.vue";
import { RouterHelper } from "@/router/routerHelper";

@Component({
  components: {
    download: DownloadVue,
  },
})
export default class Armor90DownloadVue extends Vue {
  prodCode: string = "armor90";
  prodName: string = "Armor 90";

  linkData:Array<any> = [
      { name:'Home',link:'/'},
      { name: 'Suppot',link:'#'},
        { name: this.prodName, link: ("/product/" + this.prodCode) },
      { name:'DOWNLOAD',link:'#'}, 
  ];
  tabList: Array<any> = RouterHelper.getProdRouterConfig(this.prodCode)  ;
}
