



















import AppVue from "./app";
export default AppVue;
