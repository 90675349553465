


export class CookieHelper {

    // .cnblogs.com
    // static domain:string = 'localhost' ;

    static domain:string = '.litebee.com' ;

    /** 设置cookie */
    static setCookie(name:string, value:string, day:number = 365) {
        var date = new Date();
        date.setDate(date.getDate() + day);
        //domain=.cnblogs.com;写入cookie到主域 子域名都可用    path=/表示本站全部路径都可使用
        document.cookie = name + '=' + value + ';expires=' + date + ';domain=' + CookieHelper.domain + ';path=/';
    };


    /**获取cookie */
    static getCookie(name:string ) {
        var reg = RegExp(name + '=([^;]+)');
        var arr = document.cookie.match(reg);
        if (arr) {
            return arr[1];
        } else {
            return '';
        }
    };

}

