import Axios, { AxiosResponse } from 'axios';
import { ConfigMgr } from '@/config';


/** http请求 */
export class HttpHelper {


    

    /** 获取json文件数据 */
    static getJsonData(filePath: string) {
        return Axios.get(ConfigMgr.jsonDir + filePath + '.json?t=' + new Date().getTime());
    }

    /** 获得产品问答数据 */
    static getProdQaJson(prodCode: string) {
        return Axios.get(ConfigMgr.jsonDir + 'prods/' + prodCode + '/qa.json?t=' + new Date().getTime());
    }

    /** 获得教学视频数据 */
    static getProdVideoJson(prodCode: string) {
        return Axios.get(ConfigMgr.jsonDir + 'prods/' + prodCode + '/video.json?t=' + new Date().getTime());
    }

    /** 获得产品下载数据 */
    static getProdDownloadJson(prodCode: string) {
        return Axios.get(ConfigMgr.jsonDir + 'prods/' + prodCode + '/download.json?t=' + new Date().getTime());
    }

    // static get(url:string):Promise<AxiosResponse<any>> {
    //     return Axios.get(ConfigMgr.jsonDir + url + '?t=' + new Date().getTime())  ;
    // }

    /** 添加订阅邮箱 */
    static subByEmail(email: string): Promise<AxiosResponse<any>> {
        return Axios.post(ConfigMgr.apiHost + '/mailbox/en?t=' + new Date().getTime(), {
            "email": email,
        })
    }

    /** 成为合作商 */
    static asPartner(name:string,phone:string,email:string,demand:string,country:string,company:string) {
        return Axios.post(ConfigMgr.apiHost + '/partner/en?t=' + new Date().getTime(), { 
            "demand": demand, 
            "email": email, 
            "mobile": phone,
            "name": name, 
            "country": country,
            "company": company,
        })
    }
 

}