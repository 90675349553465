








































import { Component, Prop, Vue } from "vue-property-decorator";
import ProduceNavVue from "@/components/product/ProductNav.vue";
import SubNavVue from "@/components/common/subNav/SubNav.vue";
import ArmorWaspQaVue from '../armorWasp/qa.vue';
import { HttpHelper } from '@/helper/httpHelper';
import { AxiosResponse } from 'axios';

@Component({
  components: {
    "product-nav": ProduceNavVue,
    "sub-nav": SubNavVue,
  },
})
export default class QaVue extends Vue {
  @Prop({
    type: String,
    default: "",
  })
  prodName?: string;

  @Prop({
    type:String,
    default:''
  })prodCode?:string ;

  typeIndex: number = 0;

  @Prop({
    type: Array,
    default: [],
  })
  tabList?: Array<any>;

 
  qaData:Array<any> = []; 

  // qaData: Array<any> = [
  //   {
  //     category: "分类一",
  //     list: [
  //       {
  //         q: "分类一问题1",
  //         a: "分类一问题1回答",
  //       },
  //       {
  //         q: "分类一问题2",
  //         a: "分类一问题2回答",
  //       },
  //     ],
  //   }, 
  // ];

  @Prop({
    type: Array,
    default: [],
  })
  linkData?: Array<any>;

  onSelectType(index: number) {
    this.typeIndex = index;
  }

  showOrHideAnswer(qaIndex: number) {
     
    this.$set(
      (this as any).qaData[this.typeIndex].list[qaIndex],
      "show",
       (this as any).qaData[this.typeIndex].list[qaIndex].show ? false : true
    );
  } 

  mounted() {
    this.$nextTick(()=>{
      HttpHelper.getProdQaJson(this.prodCode || '').then(
      (response: AxiosResponse) => {
        this.qaData = response.data.data;
      }
    );
    })
  }
}
