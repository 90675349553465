









import { Component, Prop, Vue } from "vue-property-decorator";
import QaVue from "../qa/Qa.vue";
import { RouterHelper } from "@/router/routerHelper";
import { AxiosResponse } from 'axios';
import { HttpHelper } from '@/helper/httpHelper';

@Component({
  components: {
    qa: QaVue,
  },
})
export default class LiteBeeWingFmQaVue extends Vue {

  prodName: string = "LiteBee Wing FM"; 
  prodCode: string = "liteBeeWingFm";

  tabList: Array<any> = RouterHelper.getProdRouterConfig(this.prodCode);

  linkData: Array<any> = [
    { name: "Home", link: "/" },
    { name: this.prodName, link: "#" },
    { name: "FAQ", link: "#" },
  ]; 
 
}
